import {Injectable} from "@angular/core";
import {Actions, createEffect, ofType} from "@ngrx/effects";
import {ChartService} from "@service/chart.service";
import {
  changeFilters,
  changeFiltersFailure,
  changeFiltersSuccess,
  changeProductId,
  changeProductIdSuccess,
  initDashboard,
  initDashboardFailure,
  initDashboardSuccess
} from "@store/dashboard/dashboard.action";
import {catchError, map, of, switchMap} from "rxjs";
import {notificationError} from "@components/error/error.utils";
import {initParams} from "@store/dashboard/dashboard.reducer";

@Injectable()
export class DashboardEffect {
  constructor(public actions$: Actions, public service: ChartService) {
  }

  initDashboard$ = createEffect(() => this.actions$.pipe(
    ofType(initDashboard),
    switchMap(({allowedProductIdList})=> this.service.getCharts({...initParams, allowedProductIdList}).pipe(
      map(charts => initDashboardSuccess({charts, allowedProductIdList})),
        catchError((error) => of(initDashboardFailure({error: notificationError(error)}))))),
    )
  )

  changeProductId$ = createEffect(() => this.actions$.pipe(
    ofType(changeProductId),
    switchMap(({productId}) => {
      if (productId === '') {
        return of(changeProductIdSuccess({data: []}));
      } else {
        return this.service.getEventType(productId).pipe(
          map(eventTypeList => changeProductIdSuccess({data: eventTypeList.data})),
          catchError((error) => of(initDashboardFailure({error: notificationError(error)})))
        );
      }
    })
  ))

  changeFilters$ = createEffect(() => this.actions$.pipe(
    ofType(changeFilters),
    switchMap(({filters}) => this.service.getCharts(filters).pipe(
      map(body => changeFiltersSuccess({data: body, filters})),
      catchError((error) => of(changeFiltersFailure({error: notificationError(error)})))
    ))
  ))
}
