import {Component, EventEmitter, Input, Output} from '@angular/core';
import {HeaderbarButtonComponent} from "@components/header-bar/button/header-button.component";
import {HeaderModalComponent} from "@components/header-bar/modal/header-modal.component";
import {FiltersDto} from "@dto/filters.dto";

@Component({
  selector: 'tmn-header-bar',
  standalone: true,
  templateUrl: './header-bar.component.html',
  styleUrl: './header-bar.component.css',
  imports: [
    HeaderbarButtonComponent,
    HeaderModalComponent
  ],
})
export class HeaderBarComponent {
  @Input() numberOfNewNotifications: number = 0;
  @Input() disconnected: boolean = false;
  @Input() currentPageNumber!: number;
  @Input() filters!: FiltersDto;
  @Input() pageSize!: number;
  @Input() visible: boolean = false;
  @Output() public close: EventEmitter<void> = new EventEmitter<void>();

  refreshNotification() {
    this.close.emit();
  }
}
