/* eslint-disable @typescript-eslint/no-explicit-any,@typescript-eslint/no-unsafe-return, @typescript-eslint/no-unsafe-argument */

import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import {
  OAuthService,
  OAuthResourceServerErrorHandler,
  OAuthModuleConfig,
} from 'angular-oauth2-oidc';
import { Observable, catchError } from 'rxjs';

export class IdTokenAuthInterceptor implements HttpInterceptor {
  constructor(
    private oAuthService: OAuthService,
    private errorHandler: OAuthResourceServerErrorHandler,
    private moduleConfig: OAuthModuleConfig
  ) {}

  public intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (
      !this.moduleConfig ||
      !this.moduleConfig.resourceServer ||
      !this.checkUrl(req.url.toLowerCase())
    ) {
      return next.handle(req);
    }

    const token = this.oAuthService.getIdToken();

    const headers = req.headers.set('Authorization', `Bearer ${token}`);

    return next
      .handle(req.clone({ headers }))
      .pipe(catchError((err) => this.errorHandler.handleError(err)));
  }

  private checkUrl(url: string): boolean {
    if (this.moduleConfig?.resourceServer?.allowedUrls) {
      return !!this.moduleConfig.resourceServer.allowedUrls.find((u) =>
        url.toLowerCase().startsWith(u.toLowerCase())
      );
    }
    return true;
  }
}
