import {notificationListReducer, NotificationListState} from "./notification-list/notification-list.reducer";
import {NotificationListEffect} from "./notification-list/notification-list.effect";
import {
  notificationDetailReducer,
  NotificationDetailState
} from "@store/notification-detail/notification-detail.reducer";
import {NotificationDetailEffect} from "@store/notification-detail/notification-detail.effect";
import {ChartsState, dashboardReducer} from "@store/dashboard/dashboard.reducer";
import {DashboardEffect} from "@store/dashboard/dashboard.effect";
import {userInfosReducer, UserInfosState} from "@store/user-infos/user-infos.reducer";
import {UserInfosEffect} from "@store/user-infos/user-infos.effect";

export interface State {
  notificationList: NotificationListState,
  notificationDetail: NotificationDetailState,
  charts: ChartsState,
  userInfos: UserInfosState
}


export const reducers = {
  notificationList: notificationListReducer,
  notificationDetail: notificationDetailReducer,
  charts: dashboardReducer,
  userInfos: userInfosReducer
}

export const effects = [
  NotificationListEffect,
  NotificationDetailEffect,
  DashboardEffect,
  UserInfosEffect
]

export const runtimeChecks = {
  // Default On:
  strictStateImmutability: true, //  verifies that the state isn't mutated.
  strictActionImmutability: true, //  verifies that actions aren't mutated
  // Default Off:
  strictStateSerializability: true, // verifies if the state is serializable
  strictActionSerializability: true, // verifies if the actions are serializable -- Peut poser problème si on utiliser un localStorage par exemple
  strictActionWithinNgZone: true, // verifies if actions are dispatched within NgZone
  strictActionTypeUniqueness: true, // verifies if registered action types are unique
};
