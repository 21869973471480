import {Component, Input} from "@angular/core";
import {NgStyle} from "@angular/common";
import {BehaviorSubject, combineLatest, map, Observable} from "rxjs";
import {BubbleProps} from "@type/bubble-props.type";
import {LetDirective} from "@ngrx/component";

@Component({
  standalone: true,
  selector: 'tmn-bubble',
  templateUrl: './bubble.component.html',
  styleUrls: ['./bubble.component.css'],
  imports: [
    NgStyle,
    LetDirective
  ]
}) export class BubbleComponent {
  private gradColorPrimary$: BehaviorSubject<string> = new BehaviorSubject<string>("#FFCD00");
  private gradColorSecondary$: BehaviorSubject<string> = new BehaviorSubject<string>("#f23d7b");
  private gradColorAngle$: BehaviorSubject<number> = new BehaviorSubject<number>(-45);
  private size$: BehaviorSubject<number> = new BehaviorSubject<number>(200);
  private animationDuration$: BehaviorSubject<number> = new BehaviorSubject<number>(5);
  public bubbleProps$: Observable<BubbleProps> = combineLatest([
    this.gradColorPrimary$,
    this.gradColorSecondary$,
    this.gradColorAngle$,
    this.size$,
    this.animationDuration$
  ]).pipe(
    map(([gradColorPrimary, gradColorSecondary, gradColorAngle, size, animationDuration]) => ({
      background: `linear-gradient(${gradColorAngle}deg, ${gradColorPrimary}, ${gradColorSecondary})`,
      "border-radius": "50%",
      width: `${size}px`,
      height: `${size}px`,
      "animation-duration": `${animationDuration}s`
    }))
  );

  @Input() set gradColorPrimary(value: string) {
    this.gradColorPrimary$.next(value);
  }
  @Input() set gradColorSecondary(value: string) {
    this.gradColorSecondary$.next(value);
  }
  @Input() set gradColorAngle(value: number) {
    this.gradColorAngle$.next(value);
  }
  @Input() set size(value: number) {
    this.size$.next(value);
  }
  @Input() set animationDuration(value: number) {
    this.animationDuration$.next(value);
  }
}
