const getStatus = (jsonError: any): number => {
  if (jsonError?.error?.statusCode) {
    return jsonError.error.statusCode;
  }

  return 500;
}

const getErrorMessage = (jsonError: any): string => {
  if (jsonError?.error?.message) {
    return jsonError.error.message;
  }

  return "Service inaccessible";
}

export function notificationError(jsonError: any): string {
  const status = getStatus(jsonError);
  const errorMessage = getErrorMessage(jsonError);

  return "Erreur " + status + ": " + errorMessage;
}
