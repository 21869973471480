import {createReducer, on} from "@ngrx/store";
import {
  initUserAllowedProductIdList,
  initUserAllowedProductIdListFailure,
  initUserAllowedProductIdListSuccess
} from "@store/user-infos/user-infos.action";
import {NullableType} from "@type/nullable.type";
import {AuthStorageDto} from "@dto/auth-storage.dto";

export interface UserInfosState {
  authInfos: NullableType<AuthStorageDto>,
  userAuthorizations: {
    isUserAuthorizedToPushNotif: boolean,
    userAllowedProductIdList: string[],
    isLoading: boolean,
    error: NullableType<string>
  }
}

export const initialState: UserInfosState = {
  authInfos: null,
  userAuthorizations: {
    isUserAuthorizedToPushNotif: false,
    userAllowedProductIdList: [],
    isLoading: false,
    error: null
  }
};

export const userInfosReducer = createReducer(
  initialState,
  on(initUserAllowedProductIdList, (state, {authInfos}) => (
    {
      ...initialState,
      authInfos,
      userAuthorizations: {...initialState.userAuthorizations, isLoading: true}
    })
  ),

  on(initUserAllowedProductIdListSuccess, (state, {userAuthorizations}) => (
    {
      ...state,
      userAuthorizations: {
        ...state.userAuthorizations,
        userAllowedProductIdList: userAuthorizations.userAllowedProductIdList,
        isUserAuthorizedToPushNotif: userAuthorizations.isUserAuthorizedToPushNotif,
        isLoading: false
      }
    })
  ),

  on(initUserAllowedProductIdListFailure, (state, {error}) => (
    {
      ...state,
      userAuthorizations: {...state.userAuthorizations, error, isLoading: false}
    })
  ),
);
