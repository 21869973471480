import {
  AuthConfig,
  OAuthModule,
  OAuthModuleConfig,
  OAuthResourceServerErrorHandler,
  OAuthService
} from "angular-oauth2-oidc";
import {authConfig} from "@auth/auth.config";
import {AuthService, authServiceFactory} from "@auth/auth.service";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import {APP_INITIALIZER, importProvidersFrom} from "@angular/core";
import {initLogin, authHttpInterceptor} from '@auth/auth.provider.utils'
import {environment} from "@env/environment";

const authModuleConfig: OAuthModuleConfig = {
  resourceServer: {
    allowedUrls: [environment.API_URL, environment.WS_URL],
    sendAccessToken: true,
  },
};

export const authProviderConfig =  [
  importProvidersFrom(OAuthModule.forRoot()),
  { provide: AuthConfig, useValue: authConfig },
  { provide: OAuthModuleConfig, useValue: authModuleConfig },
  {
    provide: AuthService,
    useFactory: authServiceFactory,
    deps: [OAuthService],
  },
  {
    provide: HTTP_INTERCEPTORS,
    useFactory: authHttpInterceptor,
    multi: true,
    deps: [
      OAuthService,
      OAuthResourceServerErrorHandler,
      OAuthModuleConfig,
    ],
  },
  {
    provide: APP_INITIALIZER,
    useFactory: initLogin,
    deps: [AuthService],
    multi: true,
  },
  ];
