import { Injectable } from "@angular/core";
import {Stomp} from "@stomp/stompjs";
import { environment } from "@env/environment";
import SockJS from "sockjs-client";

@Injectable({ providedIn: "any" })
export class WebsocketService {
  private serverUrl = `${environment.WS_URL}/notification`;
  private stompClient: any;
  public mapEndpointSubscription: Map<string, any> = new Map();

  public async initWebSocket() {
    return new Promise((resolve, reject) => {
      if (!this.stompClient) {
        const ws = new SockJS(this.serverUrl);
        this.stompClient = Stomp.over(ws);
        this.stompClient.connect({}, resolve);
        this.stompClient.onWebSocketClose = () => {
          reject(new Error("WebSocket closed due to invalid WebSocket URL"));
        };
      } else {
        resolve("Already connected");
      }
    });
  }

  public async subscribe(name: string, fnc: (event: any) => void) {
    const subscription = this.stompClient.subscribe(
      `/${name}`,
      (event: any) => {
        fnc({ ...event, body: JSON.parse(event.body) });
      },
    );
    this.mapEndpointSubscription.set(name, subscription);
  }

  public unsubscribeToWebSocketEvent(name: string) {
    const subscription = this.mapEndpointSubscription.get(name);
    if (subscription) {
      subscription.unsubscribe();
    }
  }

  public disconnect() {
    if (this.stompClient !== null) {
      this.stompClient.disconnect();
    }
  }
}
