<div class="bar-style bar-properties">
  <img src="assets/logo-bpi.svg" class="logo-bpi" alt="logo-bpi">
  <span class="track-ma-notif">Track Ma Notif</span>
  <div class="button-container">
    <tmn-header-bar-button [visible]="visible"
    ></tmn-header-bar-button>
  </div>
</div>
<tmn-header-modal [numberOfNewNotifications]="numberOfNewNotifications"
                  [currentPageNumber]="currentPageNumber"
                  [disconnected]="disconnected"
                  [filters]="filters"
                  [pageSize]="pageSize"
                  (close)="refreshNotification()"
></tmn-header-modal>

