import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {environment} from "@env/environment";
import {ChartsDto} from "@dto/charts.dto";
import {ChartsParamsDto} from "@dto/charts-params.dto";
import {AuthHttpClientService} from "@service/auth-http-client.service";
import {EventTypeListDto} from "@dto/eventtype-list.dto";

@Injectable({
  providedIn: 'any',
})
export class ChartService {
  private readonly url: string = environment.API_URL;

  constructor(private http: AuthHttpClientService) {
  }

  getCharts(filters: ChartsParamsDto): Observable<ChartsDto> {
    return this.http.post<ChartsDto>(`${this.url}/charts/all`, filters);
  }

  getEventType(productId: string): Observable<EventTypeListDto> {
    return this.http.get<EventTypeListDto>(`${this.url}/notification/${productId}/eventtypes`);
  }
}
