import {Actions, createEffect, ofType} from "@ngrx/effects";
import {NotificationService} from "@service/notification.service";
import {
  initNotification,
  loadNotificationPage,
  loadNotificationPageFailure,
  loadNotificationPageSuccess,
} from "./notification-list.action";
import {catchError, map, of, switchMap} from "rxjs";
import {Injectable} from "@angular/core";
import {notificationError} from "@components/error/error.utils";
import {initFilters} from "@store/notification-list/notification-list.reducer";

@Injectable()
export class NotificationListEffect {
  constructor(private actions$: Actions, private notificationService: NotificationService) {
  }

  initNotificationPage$ = createEffect(() => this.actions$.pipe(
    ofType(initNotification),
    switchMap(({allowedProductIdList}) => of(loadNotificationPage({
        currentPageNumber: 1,
        filters: {...initFilters, allowedProductIdList},
        pageSize: 10,
        reload: false
      }))
    ))
  );

  loadNotificationPage$ = createEffect(() => this.actions$.pipe(
      ofType(loadNotificationPage),
      switchMap(({currentPageNumber, filters, pageSize, reload}) =>
        this.notificationService.getNotificationList(currentPageNumber, filters, pageSize)
          .pipe(
            map(body => loadNotificationPageSuccess({
              notificationList: body,
              reload: reload && currentPageNumber === 1
            })),
            catchError((error) => of(loadNotificationPageFailure({error: notificationError(error)})
              )
            ),
          )
      )
    )
  );
}
