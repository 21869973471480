import {Component, EventEmitter, Input, Output} from "@angular/core";
import {LetDirective} from "@ngrx/component";
import {Store} from "@ngrx/store";
import {State} from "@store/state";
import {loadNotificationPage} from "@store/notification-list/notification-list.action";
import {Router} from "@angular/router";
import {PluralPipe} from "@pipe/plural.pipe";
import {FiltersDto} from "@dto/filters.dto";

@Component({
    selector: 'tmn-header-modal',
    standalone: true,
    templateUrl: './header-modal.component.html',
    styleUrl: './header-modal.component.scss',
    imports: [
      LetDirective,
      PluralPipe,
    ]
  }
)
export class HeaderModalComponent {
  @Input() numberOfNewNotifications: number = 0;
  @Input() disconnected: boolean = false;
  @Input() currentPageNumber!: number;
  @Input() filters!: FiltersDto;
  @Input() pageSize!: number;
  @Output() close: EventEmitter<void> = new EventEmitter<void>();

  public isModalOpen: boolean = true;

  constructor(public router: Router, public store: Store<State>) {
  }

  refreshNotification() {
    this.store.dispatch(loadNotificationPage({
      currentPageNumber: this.currentPageNumber,
      filters: this.filters,
      pageSize: this.pageSize,
      reload: true
    }));
    this.close.emit();
  }

  closeModal() {
    this.isModalOpen = false;
  }
}
