import {OAuthService} from 'angular-oauth2-oidc';
import {BehaviorSubject, from, Observable, of, takeWhile} from 'rxjs';
import {switchMap, tap} from 'rxjs/operators';
import {environment} from '@env/environment';
import {AbstractSubscribable} from './abstract-subscribable';
import {authConfig} from './auth.config';

export function authServiceFactory(oauthService: OAuthService): AuthService {
  return environment.SSO.enabled
    ? new DefaultAuthService(oauthService)
    : new LocalAuthService();
}

export abstract class AuthService {
  abstract login: () => Observable<boolean>;
}

export class DefaultAuthService
  extends AbstractSubscribable
  implements AuthService
{
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);

  private isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();

  private isDoneLoadingSubject$ = new BehaviorSubject<boolean>(false);

  constructor(private oauthService: OAuthService) {
    super();
    this.oauthService.events.subscribe(() =>
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken())
    );

    this.oauthService.setupAutomaticSilentRefresh();
  }

  public login(targetUrl?: string): Observable<boolean> {
    return from(this.runInitialLoginSequence()).pipe(
      takeWhile(() => this.subscribe),
      switchMap(() => this.isAuthenticated$),
      tap(
        (isAuthenticated) =>
          isAuthenticated || this.oauthService.initLoginFlow(targetUrl)
      )
    );
  }

  private runInitialLoginSequence(): Promise<void> {
    return this.oauthService
      .loadDiscoveryDocument(
        `${authConfig.issuer}/${environment.SSO.discoveryDocumentEndpoint}`
      )
      .then(() => this.oauthService.tryLogin())
      .then(() => {
        this.isDoneLoadingSubject$.next(true);
        return Promise.resolve();
      })
      .catch(() => this.isDoneLoadingSubject$.next(true));
  }
}

class LocalAuthService implements AuthService {
  login(): Observable<boolean> {
    localStorage.setItem('key', 'my-key');
    return of(true);
  }
}
