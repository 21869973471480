
import {Component, Input} from '@angular/core';
import {RouterLink, RouterLinkActive} from "@angular/router";
import { Store } from '@ngrx/store';
import { State } from '@store/state';
import { getUserAllowedProductIdList } from '@store/user-infos/user-infos.selector';
import { Observable } from 'rxjs';

@Component({
  selector: 'tmn-header-bar-button',
  standalone: true,
  templateUrl: './header-button.component.html',
  styleUrl: './header-button.component.scss',
  imports: [
    RouterLink,
    RouterLinkActive
]
})
export class HeaderbarButtonComponent {
  @Input() visible! : boolean;
}
