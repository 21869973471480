import {AuthService} from "@auth/auth.service";
import {filter, firstValueFrom} from "rxjs";
import {
  DefaultOAuthInterceptor,
  OAuthModuleConfig,
  OAuthResourceServerErrorHandler,
  OAuthService
} from "angular-oauth2-oidc";
import { HttpInterceptor } from "@angular/common/http";
import {environment} from "@env/environment";
import {IdTokenAuthInterceptor} from "@auth/id-token-auth.interceptor";

export function initLogin(authService: AuthService): () => Promise<boolean> {
  return (): Promise<boolean> =>
    firstValueFrom(
      authService
        .login()
        // prevent application starting before authentication is done
        .pipe(filter((loggedIn) => loggedIn))
    );
}

export function authHttpInterceptor(
  oAuthService: OAuthService,
  errorHandler: OAuthResourceServerErrorHandler,
  moduleConfig: OAuthModuleConfig
): HttpInterceptor {
  return environment.SSO.useAccessToken
    ? new DefaultOAuthInterceptor(oAuthService, errorHandler, moduleConfig)
    : new IdTokenAuthInterceptor(oAuthService, errorHandler, moduleConfig);
}
