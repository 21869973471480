<ng-container *ngrxLet="initApp$">
  <tmn-header-bar
    *ngrxLet="{numberOfNewNotification: numberOfNewNotification$, disconnected: disconnected$, currentPageNumber: reloadCurrentPage$, filters: reloadFilters$, pageSize: reloadPageSize$, visible: visible$} as props"
    [numberOfNewNotifications]="props.numberOfNewNotification ?? 0"
    [disconnected]="props.disconnected"
    [currentPageNumber]="props.currentPageNumber"
    [filters]="props.filters"
    [pageSize]="props.pageSize"
    [visible]="props.visible"
    (close)="refreshNotification()"
  ></tmn-header-bar>
  <div class='router'>
    <tmn-background></tmn-background>
    <div class="content">
      <router-outlet></router-outlet>
    </div>
  </div>

</ng-container>
