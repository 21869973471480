import { OnDestroy, Injectable } from '@angular/core';

@Injectable()
export abstract class AbstractSubscribable implements OnDestroy {
  protected subscribe = true;

  ngOnDestroy(): void {
    this.subscribe = false;
  }
}
