import {NotificationDto} from "@dto/notification.dto";
import {createReducer, on} from "@ngrx/store";
import {
  initNotification,
  loadNotificationPage,
  loadNotificationPageFailure,
  loadNotificationPageSuccess,
  updateNotificationFromWs
} from "./notification-list.action";
import {NullableType} from "@type/nullable.type";
import {FiltersDto} from "@dto/filters.dto";

export const initFilters : FiltersDto = {
  sourceId: "",
  eventType: "",
  productId: "",
  receiver: "",
  date: "",
  status: "",
  allowedProductIdList: [],
};

export interface NotificationListState {
  notification: { data: NotificationDto[], filters: FiltersDto, isLoading: boolean, error: NullableType<string> };
  notificationPagination: {
    currentPageNumber: number,
    totalNotifications: number,
    totalPages: number,
    pageSize: number
  };
  reload: boolean;
}

export const initialState: NotificationListState = {
  notification: {
    data: [],
    filters: initFilters,
    isLoading: false,
    error: null
  },
  notificationPagination: {currentPageNumber: 1, totalNotifications: 0, totalPages: 1, pageSize: 10},
  reload: false,
};

export const notificationListReducer = createReducer(
  initialState,
  on(initNotification, () => ({
      ...initialState,
      notification: {...initialState.notification, isLoading: true},
      notificationPagination: {...initialState.notificationPagination},
    })
  ),

  on(loadNotificationPage, (state, {currentPageNumber, filters, pageSize}) => (
      {
        ...state,
        notification: {...state.notification, filters, isLoading: true},
        notificationPagination: {...state.notificationPagination, currentPageNumber, pageSize},
      }
    )
  ),

  on(loadNotificationPageSuccess, (state, {notificationList, reload}) => {
      return ({
        ...state,
        notification: {...state.notification, data: notificationList.notifications, isLoading: false, error: null},
        notificationPagination: {
          ...state.notificationPagination,
          totalNotifications: notificationList.totalNotifications,
          totalPages: notificationList.totalPages
        },
        reload: reload,
      })
    }
  ),

  on(loadNotificationPageFailure, (state, {error}) =>
    ({
      ...state,
      notification: {...state.notification, error, isLoading: false},
      notificationPagination: {...initialState.notificationPagination},
    })
  ),

  on(updateNotificationFromWs, (state, {toUpdate}) => {
    const updatedNotification = state.notification.data.map((notification) => {
      if (notification.sourceId === toUpdate.sourceId) {
        return {...notification, state: toUpdate.state};
      }
      return notification;
    });
    return {
      ...state,
      notification: {...state.notification, data: updatedNotification},
    }
  }),
);
