import { AuthConfig } from 'angular-oauth2-oidc';
import { environment } from '@env/environment';

export const authConfig: AuthConfig = {
  issuer: environment.SSO.issuer,
  clientId: environment.SSO.clientId,
  redirectUri: window.location.origin,
  silentRefreshRedirectUri: window.location.origin,
  responseType: 'code',
  timeoutFactor: 0.75,
  useSilentRefresh: true,
  scope: 'openid profile email',
};
