import {Component} from "@angular/core";
import {BubbleComponent} from "@components/background/bubble/bubble.component";

@Component({
  standalone: true,
  selector: 'tmn-background',
  templateUrl: './background.component.html',
  imports: [
    BubbleComponent
  ],
  styleUrls: ['./background.component.css']
}) export class BackgroundComponent {

}
