import {NotificationDetailDto} from "@dto/notification-detail.dto";
import {NullableType} from "@type/nullable.type";
import {createReducer, on} from "@ngrx/store";
import {
  initNotificationDetail,
  initNotificationDetailFailure,
  initNotificationDetailSuccess,
  updateNotificationDetail,
} from "@store/notification-detail/notification-detail.action";

export interface NotificationDetailState {
  notificationDetail: {
    sourceId: NullableType<string>,
    data: NullableType<NotificationDetailDto>,
    isLoading: boolean,
    error: NullableType<string>
  };
}

export const initialState: NotificationDetailState = {
  notificationDetail: {sourceId: null, data: null, isLoading: false, error: null}
}

export const notificationDetailReducer = createReducer(
  initialState,
  on(initNotificationDetail, (state, {sourceId}) => (
    {
      ...initialState,
      notificationDetail: {...initialState.notificationDetail, sourceId, isLoading: true}
    })
  ),

  on(initNotificationDetailSuccess, (state, {data}) => (
    {
      ...state,
      notificationDetail: {...state.notificationDetail, data, isLoading: false}
    })
  ),

  on(initNotificationDetailFailure, (state, {error}) => (
    {
      ...state,
      notificationDetail: {...state.notificationDetail, error, isLoading: false}
    })
  ),

  on(updateNotificationDetail, (state, {toUpdate}) => {
      if (state.notificationDetail.data && state.notificationDetail.data.sourceId === toUpdate.sourceId) {
        if (toUpdate.error) {
          return {
            ...state,
            notificationDetail: {
              ...state.notificationDetail,
              data: {
                ...state.notificationDetail.data,
                errorList: [...state.notificationDetail.data.errorList, toUpdate.error],
                stateList: [...state.notificationDetail.data.stateList, toUpdate.state]
              }
            }
          }
        } else {
          return {
            ...state,
            notificationDetail: {
              ...state.notificationDetail,
              data: {
                ...state.notificationDetail.data,
                stateList: [...state.notificationDetail.data.stateList, toUpdate.state]
              }
            }
          }
        }
      }

      return (
        {
          ...state,
        })
    }
  ),
)
