import {Observable} from "rxjs";
import {Injectable} from "@angular/core";
import {environment} from "@env/environment";
import {AuthHttpClientService} from "@service/auth-http-client.service";
import {UserAuthorizationsDto} from "@dto/user-authorizations.dto";

@Injectable({
  providedIn: 'any',
})
export class UserService {
  private readonly url: string = environment.API_URL;

  constructor(private http: AuthHttpClientService) {
  }

  getAllowedProductIdList(mat: string): Observable<UserAuthorizationsDto> {
    return this.http.get<UserAuthorizationsDto>(`${this.url}/user/allowed-product-id-list?matricule=${mat}`);
  }
}
