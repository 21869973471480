import {Routes} from '@angular/router';
import { CreateNotifPageComponent } from '@pages/create-notif/create-notif.page';

export const routes: Routes = [
  {path: "", redirectTo: "notifications", pathMatch: "full"},
  {path: "notification-detail", loadComponent: () => import("./page/notification-detail/notification-detail.page").then(m => m.NotificationDetailPageComponent)},
  {path: "notifications", loadComponent: () => import("./page/notification-list/notification-list.page").then(m => m.NotificationListPageComponent)},
  {path: "dashboard", loadComponent: () => import("./page/dashboard/dashboard.page").then(m => m.DashboardPageComponent)},
  {path: "push/teams", loadComponent: () => import("./page/create-notif/create-notif.page").then(m => m.CreateNotifPageComponent)},
];
