<div class="background">
  <div class="top-left">
    <div>
      <tmn-bubble [size]="300" [animationDuration]="7.5"></tmn-bubble>
    </div>
    <tmn-bubble [size]="50" [animationDuration]="2"></tmn-bubble>
  </div>
  <div class="top-right">
    <div class="moon-first">
      <tmn-bubble  [gradColorAngle]="45" [size]="25" [animationDuration]="1"></tmn-bubble>
    </div>
    <div class="moon-second">
      <tmn-bubble  [gradColorAngle]="45" [size]="25" [animationDuration]="1"></tmn-bubble>
    </div>
    <tmn-bubble  [gradColorAngle]="45" [size]="150" [animationDuration]="3.75"></tmn-bubble>
  </div>
  <div class="down-right">
    <tmn-bubble  [gradColorAngle]="135" [size]="100"></tmn-bubble>
    <tmn-bubble  [gradColorAngle]="135" [size]="600" [animationDuration]="15"></tmn-bubble>
  </div>
</div>
