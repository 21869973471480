import {Component, OnDestroy, OnInit} from "@angular/core";
import {RouterOutlet} from "@angular/router";
import {HeaderBarComponent} from "@components/header-bar/header-bar.component";
import {registerLocaleData} from "@angular/common";
import localeFr from "@angular/common/locales/fr";
import {State} from "@store/state";
import {Store} from "@ngrx/store";
import {initNotification,} from "@store/notification-list/notification-list.action";
import {WebsocketService} from "@service/websocket.service";
import {BehaviorSubject, filter, first, map, Observable} from "rxjs";
import {LetDirective} from "@ngrx/component";
import {initDashboard,} from "@store/dashboard/dashboard.action";
import {FiltersDto} from "@dto/filters.dto";
import {
  getNotificationListFilters,
  getNotificationListPageNumber,
  getPageSize,
} from "@store/notification-list/notification-list.selector";
import {Title} from "@angular/platform-browser";
import {BackgroundComponent} from "@components/background/background.component";
import {AuthStorageDto} from "@dto/auth-storage.dto";
import {initUserAllowedProductIdList} from "@store/user-infos/user-infos.action";
import {
  getIsUserAuthorizedToPushNotif,
  getUserAllowedProductIdList,
  getUserAuthorizations
} from "@store/user-infos/user-infos.selector";
import {UserAuthorizationsDto} from "@dto/user-authorizations.dto";

@Component({
  selector: "tmn-root",
  standalone: true,
  imports: [
    RouterOutlet,
    HeaderBarComponent,
    LetDirective,
    BackgroundComponent,
  ],
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.css",
})
export class AppComponent implements OnInit, OnDestroy {
  title = "TrackMaNotif";

  public initApp$ = this.store.select(getUserAuthorizations).pipe(
    filter((userAuthorizations) => !userAuthorizations.isLoading && !userAuthorizations.error),
    first(),
    map((userAuthorizations: UserAuthorizationsDto) => {
      this.store.dispatch(initNotification({allowedProductIdList: userAuthorizations.userAllowedProductIdList}));
      this.store.dispatch(initDashboard({allowedProductIdList: userAuthorizations.userAllowedProductIdList}));
    })
  );

  public numberOfNewNotification$: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public disconnected$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false,);
  public reloadCurrentPage$: Observable<number> = this.store.select(getNotificationListPageNumber);
  public reloadFilters$: Observable<FiltersDto> = this.store.select(getNotificationListFilters);
  public reloadPageSize$: Observable<number> = this.store.select(getPageSize);
  public visible$: Observable<boolean> = this.store.select(getIsUserAuthorizedToPushNotif);

  constructor(public store: Store<State>, public ws: WebsocketService, public titleService: Title) {
    registerLocaleData(localeFr, "fr");
    this.titleService.setTitle(this.title);
  }

  ngOnInit() {
    /*
    Temporary, disabled while I haven't found a solution for filtering the incoming notification from WebSockets
    this.ws.initWebSocket().then(() => {
      this.ws.subscribe("notification", (event) => {
            const wsMessage: WebsocketMessageDto = event.body;
            if (wsMessage.instruction === "UPDATE_NOTIFICATION") {
              const payload: WebsocketUpdateDetailDto = wsMessage.payload;
              this.store.dispatch(updateNotificationFromWs({ toUpdate: payload }));
              this.store.dispatch(updateNotificationDetail({ toUpdate: payload }));
              this.store.dispatch(updateNotificationRtList({
                  newNotification: null,
                  toUpdate: payload,
                }),
                );
            } else if (wsMessage.instruction === "UPDATE_LIST") {
              const payload: NotificationDto = wsMessage.payload;
              this.numberOfNewNotification$.next(this.numberOfNewNotification$.getValue() + 1);
              this.store.dispatch(updateNotificationRtList({
                  newNotification: payload,
                  toUpdate: null,
                }),
                );
            }
          })
      })
      .catch(() => {
        this.disconnected$.next(true);
        this.ws.disconnect();
      });*/

    const authInfos: AuthStorageDto = JSON.parse(sessionStorage.getItem("id_token_claims_obj")!) as AuthStorageDto;
    this.store.dispatch(initUserAllowedProductIdList({ authInfos }));
  }

  refreshNotification() {
    this.numberOfNewNotification$.next(0);
  }

  ngOnDestroy() {
    this.ws.unsubscribeToWebSocketEvent("notification");
  }
}
